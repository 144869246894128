import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_ABOUT_DATA } from "../../../../common/data/SecD3v";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  AboutTitle,
  ContactDetails,
  ColumnContents,
} from "./section.style";

const Section = () => {
  const { sections } = SECTION_ABOUT_DATA;

  return (
    <>
      <SectionArea id="aboutUs">
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections.about_us} />
              <Text as="p" content={sections.about_linetag} />
              <Text as="p" content={sections.about_text} />
            </Box>
            <Box className="blockTitle">
              <img
                className="imageBlockAbout"
                src={sections.imageAbout}
                alt=""
              />
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <ColumnContents>
        <SectionArea id="story">
          <Container>
            <Row className="rowContents">
              <Box className="blockTitle">
                <img className="imageBlock" src={sections.image} alt="" />
                <Heading as="h3" content={sections.about_story_title} />
                <ContactDetails>{sections.about_story1}</ContactDetails>
                <ContactDetails>{sections.about_story2}</ContactDetails>
              </Box>
              <Box className="blockTitle ">
                <Heading as="h3" content={sections.about_cases_title} />
                {/* <ContactDetails>{sections.about_story1}</ContactDetails> */}
                <ul className="aboutList">
                  <li className="aboutListText">
                    Secure or sensitive collaborative software development
                    projects
                  </li>
                  <li className="aboutListText">
                    Enterprise software development
                  </li>
                  <li className="aboutListText">
                    Enterprise machine learning and data science
                  </li>
                  <li className="aboutListText">Public sector IT companies</li>
                </ul>
              </Box>
            </Row>
            <Row>
              <Box className="blockTitle">
                <a className="button" href="mailto:enquiries@secd3v.com.au">
                  <span>
                    Email us
                    <Icon icon={androidArrowForward} size={16} />
                  </span>
                </a>
              </Box>
            </Row>
          </Container>
        </SectionArea>
      </ColumnContents>
    </>
  );
};

export default Section;
